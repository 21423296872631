<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="newBoard.publicId.value"
            label="PublicId"
            :error-messages="newBoard.publicId.errors"
            @keyup="validateBoardPublicId"
            @input="newBoard.publicId.errors = []"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="newBoard.iccId.value"
            label="IccId"
            :error-messages="newBoard.iccId.errors"
            @keyup="validateBoardIccId"
            @input="newBoard.iccId.errors = []"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="newBoard.type.value"
            :items="['WOLK3', 'WOLK3_A', 'WOLK3_A_S', 'WOLK4']"
            label="Type"
            :error-messages="newBoard.type.errors"
            @blur="validateBoardType"
            @input="newBoard.type.errors = []"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="newBoard.revision.value"
            label="Revisie"
            :error-messages="newBoard.revision.errors"
            @keyup="validateBoardRevision"
            @input="newBoard.revision.errors = []"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="newBoard.comment.value"
            label="Opmerkingen"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :loading="boardLoading"
            :disabled="buttonIsDisabled"
            @click="createBoard"
          >
            Maak aan
          </v-btn>
          <v-btn
            class="ml-5"
            color="primary"
            :loading="boardLoading"
            :disabled="buttonIsDisabled"
            @click="deleteBoard"
          >
            Verwijder
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col>
          <span>CSV bestand voorbeeld:</span><br>
          <span>
            <i>
              boards/publicId;boards/iccId;boards/type;boards/revision;boards/comment
            </i> </span><br>
          <span><i>WLK000M;1919191919191919191;Cloud 3;1;comment</i></span>
          <v-file-input
            v-model="newBoard.csv.value"
            :error-messages="newBoard.csv.errors"
            accept="text/csv"
            show-size
            label="Selecteer een CSV bestand"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="buttonIsDisabled"
            @click="handleFileUpload"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      boardLoading: false,
      newBoard: {
        publicId: {
          value: '',
          errors: [],
        },
        iccId: {
          value: '',
          errors: [],
        },
        type: {
          value: '',
          errors: [],
        },
        revision: {
          value: '',
          errors: [],
        },
        comment: {
          value: '',
          errors: [],
        },
        csv: {
          value: null,
          errors: [],
        },
      },
    };
  },
  computed: {
    buttonIsDisabled() {
      return (
        this.loading === true
        || this.boardLoading === true
      );
    },
  },
  methods: {
    createBoard() {
      this.validateForm();

      if (this.newBoard.publicId.errors.length > 0) return;
      if (this.newBoard.iccId.errors.length > 0) return;
      if (this.newBoard.type.errors.length > 0) return;
      if (this.newBoard.revision.errors.length > 0) return;

      const url = `${config.VUE_APP_API_BASE_URL}/board`;
      this.boardLoading = true;

      const data = {
        publicId: this.newBoard.publicId.value,
        iccId: this.newBoard.iccId.value,
        type: this.newBoard.type.value,
        revision: this.newBoard.revision.value,
        comment: this.newBoard.comment.value,
      };

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Board succesvol aangemaakt.',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon het board niet aanmaken: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.boardLoading = false;
        });
    },

    deleteBoard() {
      this.validateBoardPublicId();

      if (this.newBoard.publicId.errors.length > 0) return;

      const url = `${config.VUE_APP_API_BASE_URL}/board/${this.newBoard.publicId.value}`;
      this.boardLoading = true;
      axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Board succesvol verwijderd.',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon het board niet verwijderen: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.boardLoading = false;
        });
    },

    resetFields() {
      this.newBoard = {
        publicId: {
          value: '',
          errors: [],
        },
        iccId: {
          value: '',
          errors: [],
        },
        type: {
          value: '',
          errors: [],
        },
        revision: {
          value: '',
          errors: [],
        },
        comment: {
          value: '',
          errors: [],
        },
        csv: {
          value: null,
          errors: [],
        },
      };
    },

    readFile() {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.readAsText(this.newBoard.csv.value);
        reader.onload = () => {
          resolve(this.convertCSVtoJSON(reader.result));
        };
        reader.onerror = reject;
      });
    },

    convertCSVtoJSON(text) {
      const lines = text.split(/\r?\n/);
      const subHeaderArr = [];
      const type = lines[0].split(';')[0].split('/')[0];
      lines[0].split(';').forEach((subHeader) => {
        subHeaderArr.push(subHeader.split('/')[1]);
      });
      const dataArray = [];
      const linesSliced = lines.slice(1);
      for (let i = 0; i < linesSliced.length; i += 1) {
        const line = linesSliced[i];
        const fields = line.split(';'); // e.g. ['123','30cm']
        if (line.trim().length > 0) {
          dataArray.push(Object.fromEntries(subHeaderArr.map((sh, j) => [sh, fields[j].trim()])));
        }
      }
      const output = {};
      output[type] = dataArray;
      return JSON.stringify(output);
    },

    async handleFileUpload() {
      this.validateCsvUpload();
      const file = await this.readFile();
      const url = `${config.VUE_APP_API_BASE_URL}/boards`;
      this.loading = true;
      const data = file;
      axios({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: `${this.newBoard.csv.value.name} succesvol verwerkt.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon ${this.newBoard.csv.value.name} niet verwerken. ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateForm() {
      this.validateBoardPublicId();
      this.validateBoardIccId();
      this.validateBoardType();
      this.validateBoardRevision();
    },

    validateBoardPublicId() {
      this.newBoard.publicId.errors = [];
      this.newBoard.publicId.value = this.newBoard.publicId.value.trim();
      if (!this.newBoard.publicId.value) {
        this.newBoard.publicId.errors.push('PublicId is verplicht.');
      }
      const pattern = /^(WLK00[0-9](S|M|L|XL)[0-9]+)$|^WLK[0-9]+(S|M|L|XL)$/;
      if (!pattern.test(this.newBoard.publicId.value)) {
        if (this.newBoard.type.value === 'WOLK4') {
          this.newBoard.publicId.errors.push(
            'PublicId moet starten met WLK006 gevolgd door de maat (S,M,L of XL) en 4 cijfers.',
          );
        } else {
          this.newBoard.publicId.errors.push(
            'PublicId moet starten met WLK00 en eindigen met de maat (S,M,L of XL).',
          );
        }
      }
    },

    validateBoardIccId() {
      this.newBoard.iccId.errors = [];
      this.newBoard.iccId.value = this.newBoard.iccId.value.trim();
      if (!this.newBoard.iccId.value) {
        this.newBoard.iccId.errors.push('IccId is verplicht.');
      }
      const pattern = /^\d{19}$/;
      if (!pattern.test(this.newBoard.iccId.value)) {
        this.newBoard.iccId.errors.push('IccId moet 19 cijfers lang zijn.');
      }
    },

    validateBoardType() {
      this.newBoard.type.errors = [];
      this.newBoard.type.value = this.newBoard.type.value.trim();
      if (!this.newBoard.type.value) {
        this.newBoard.type.errors.push('Type is verplicht.');
      }
    },

    validateBoardRevision() {
      this.newBoard.revision.errors = [];
      this.newBoard.revision.value = this.newBoard.revision.value.trim();
      const pattern = /^\+?[1-9]\d*$/;
      if (
        this.newBoard.revision.value
        && !pattern.test(this.newBoard.revision.value)
      ) {
        this.newBoard.revision.errors.push(
          'Revisie moet een positief cijfer zijn.',
        );
      }
    },

    validateCsvUpload() {
      this.newBoard.csv.errors = [];
      if (!this.newBoard.csv.value) {
        this.newBoard.csv.errors.push('CSV file is verplicht.');
      }
    },
  },
};
</script>
