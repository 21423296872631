<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs>
            <v-tab><v-card-title>Sleeves</v-card-title></v-tab>
            <v-tab><v-card-title>Boards</v-card-title></v-tab>
            <v-tab><v-card-title>Koppel</v-card-title></v-tab>
            <v-tab><v-card-title>Download</v-card-title></v-tab>
            <v-tab-item>
              <add-sleeve />
            </v-tab-item>
            <v-tab-item>
              <add-board />
            </v-tab-item>
            <v-tab-item>
              <link-sleeve-board />
            </v-tab-item>
            <v-tab-item>
              <download />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createBreadcrumbs } from '@/helpers/humanizer';
import AddSleeve from '../../components/admin/AddSleeve.vue';
import AddBoard from '../../components/admin/AddBoard.vue';
import LinkSleeveBoard from '../../components/admin/LinkSleeveBoard.vue';
import Download from '../../components/admin/Download.vue';

export default {
  name: 'WolkAdministrationInsertAndLinkPage',
  components: {
    AddSleeve,
    AddBoard,
    LinkSleeveBoard,
    Download,
  },
  data() {
    return {};
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Administratie',
            disabled: true,
            exact: true,
            to: { name: 'home' },
          },
          {
            text: 'Invoeren en koppelen',
            disabled: true,
            exact: true,
            to: { name: 'administrationInsertAndLink' },
          },
        ],
        false,
      );
    },
  },
};
</script>
