<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="boardAssignment.sleeveId.value"
            label="SleeveId"
            :error-messages="boardAssignment.sleeveId.errors"
            @input="validateSleeveId"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="boardAssignment.boardId.value"
            label="BoardId"
            :error-messages="boardAssignment.boardId.errors"
            @input="validateBoardId"
            @keyup="uppercase"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :disabled="linkButtonIsDisabled"
            :loading="coupleLoading"
            @click="linkSleeveBoard"
          >
            Koppel
          </v-btn>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-5"
                v-bind="attrs"
                :disabled="unlinkButtonIsDisabled"
                v-on="on"
              >
                Ontkoppel
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span
                  class="text-h6"
                >Bent u zeker dat u de sleeve en het board wilt
                  ontkoppelen?</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-checkbox
                    v-model="checkboxSleeve"
                    :label="`Verwijder de sleeve definitief`"
                  />
                  <v-checkbox
                    v-model="checkboxBoard"
                    :label="`Verwijder het board`"
                  />
                </v-container>
              </v-card-text>
              <v-card-text>
                Opgelet! De ids van de verwijderde sleeves en boards kunnen niet
                opnieuw gebruikt worden bij invoeren.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  :loading="uncoupleLoading"
                  @click="unLinkSleeveBoard"
                >
                  Ontkoppel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
                >
                  Terug
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col>
          <span>CSV bestand voorbeeld:</span><br>
          <span><i>boardAssignments/boardId;boardAssignments/sleeveId</i></span><br>
          <span><i>WLK0012345M;5432555</i></span>
          <v-file-input
            v-model="boardAssignment.csv.value"
            :error-messages="boardAssignment.csv.errors"
            accept="text/csv"
            show-size
            label="Selecteer een CSV file"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled=" uploadButtonIsDisabled"
            @click="handleFileUpload"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      uncoupleLoading: false,
      coupleLoading: false,
      loading: false,
      boardAssignment: {
        boardId: {
          value: 'WLK00',
          errors: [],
        },
        sleeveId: {
          value: '',
          errors: [],
        },
        csv: {
          value: null,
          errors: [],
        },
      },
      checkboxSleeve: false,
      checkboxBoard: false,
      dialog: false,
    };
  },
  computed: {
    linkButtonIsDisabled() {
      return (
        !this.boardAssignment.sleeveId.value
        || this.boardAssignment.sleeveId.value === ''
        || !this.boardAssignment.boardId.value
        || this.boardAssignment.boardId.value === ''
        || this.boardAssignment.sleeveId.errors.length > 0
        || this.boardAssignment.boardId.errors.length > 0
        || this.coupleLoading === true
        || this.uncoupleLoading === true
        || this.loading === true
      );
    },
    unlinkButtonIsDisabled() {
      return (
        ((!this.boardAssignment.sleeveId.value
          || this.boardAssignment.sleeveId.value === '')
          && (!this.boardAssignment.boardId.value
            || this.boardAssignment.boardId.value === ''))
        || this.boardAssignment.sleeveId.errors.length > 0
        || this.boardAssignment.boardId.errors.length > 0
        || this.coupleLoading === true
        || this.uncoupleLoading === true
        || this.loading === true
      );
    },
    uploadButtonIsDisabled() {
      return (
        this.coupleLoading === true
        || this.uncoupleLoading === true
        || this.loading === true
      );
    },
  },
  methods: {
    uppercase() {
      this.boardAssignment.boardId.value = this.boardAssignment.boardId.value
        .toUpperCase();
    },
    linkSleeveBoard() {
      this.validateBoardId();
      this.validateSleeveId();

      if (this.boardAssignment.boardId.errors.length > 0) return;
      if (this.boardAssignment.sleeveId.errors.length > 0) return;

      const url = `${config.VUE_APP_API_BASE_URL}/board-assignment`;

      this.coupleLoading = true;

      const data = {
        boardId: this.boardAssignment.boardId.value,
        sleeveId: this.boardAssignment.sleeveId.value,
      };

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Koppeling succesvol aangemaakt, Sim geactiveerd.',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon niet koppelen: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.coupleLoading = false;
        });
    },

    unLinkSleeveBoard() {
      this.validateBoardId();
      this.validateSleeveId();

      if (this.boardAssignment.boardId.errors.length > 0) return;
      if (this.boardAssignment.sleeveId.errors.length > 0) return;

      const url = `${config.VUE_APP_API_BASE_URL}/board-assignment`;

      this.uncoupleLoading = true;

      const data = {
        boardId: this.boardAssignment.boardId.value,
        sleeveId: this.boardAssignment.sleeveId.value,
        deleteSleeve: this.checkboxSleeve,
        deleteBoard: this.checkboxBoard,
      };

      axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Ontkoppeling succesvol uitgevoerd, Sim gedeactiveerd',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon niet ontkoppelen: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.uncoupleLoading = false;
          this.checkboxSleeve = false;
          this.checkboxBoard = false;
        });
      this.dialog = false;
    },

    resetFields() {
      this.boardAssignment = {
        boardId: {
          value: '',
          errors: [],
        },
        sleeveId: {
          value: '',
          errors: [],
        },
        csv: {
          value: null,
          errors: [],
        },
      };
    },

    readFile() {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.readAsText(this.boardAssignment.csv.value);
        reader.onload = () => {
          resolve(this.convertCSVtoJSON(reader.result));
        };
        reader.onerror = reject;
      });
    },

    convertCSVtoJSON(text) {
      const lines = text.split(/\r?\n/);
      const subHeaderArr = [];
      const type = lines[0].split(';')[0].split('/')[0];
      lines[0].split(';').forEach((subHeader) => {
        subHeaderArr.push(subHeader.split('/')[1]);
      });
      const dataArray = [];
      const linesSliced = lines.slice(1);
      for (let i = 0; i < linesSliced.length; i += 1) {
        const line = linesSliced[i];
        const fields = line.split(';'); // e.g. ['123','30cm']
        if (line.trim().length > 0) {
          dataArray.push(Object.fromEntries(subHeaderArr.map((sh, j) => [sh, fields[j].trim()])));
        }
      }
      const output = {};
      output[type] = dataArray;
      return JSON.stringify(output);
    },

    async handleFileUpload() {
      this.validateCsvUpload();
      const file = await this.readFile();
      const url = `${config.VUE_APP_API_BASE_URL}/board-assignments`;
      this.loading = true;
      const data = file;
      axios({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: `${this.boardAssignment.csv.value.name} succesvol verwerkt.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon ${this.boardAssignment.csv.value.name} niet verwerken: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateBoardId() {
      const errors = [];
      const pattern = /^(WLK00[0-9](S|M|L|XL)[0-9]+)$|^WLK[0-9]+(S|M|L|XL)$/;
      if (
        this.boardAssignment.boardId.value
        && !pattern.test(this.boardAssignment.boardId.value.toUpperCase())
      ) {
        errors.push(
          'BoardId moet starten met WLK00 en eindigen met de maat (S,M,L of XL). Voor een Wolk4 moet BoardId starten met WLK006, gevolgd door de maat (S,M,L of XL) en 4 cijfers.',
        );
      }
      this.boardAssignment.boardId.errors = errors;
    },

    validateSleeveId() {
      const errors = [];
      const pattern = /^\d+$/;
      if (
        this.boardAssignment.sleeveId.value
        && (!pattern.test(this.boardAssignment.sleeveId.value)
          || this.boardAssignment.sleeveId.value.length < 5
          || this.boardAssignment.sleeveId.value.length > 7)
      ) {
        errors.push('SleeveId moet 5-7 cijfers lang zijn.');
      }
      this.boardAssignment.sleeveId.errors = errors;
    },

    validateCsvUpload() {
      this.boardAssignment.csv.errors = [];
      if (!this.boardAssignment.csv.value) {
        this.boardAssignment.csv.errors.push('CSV file is verplicht.');
      }
    },
  },
};
</script>
