<template>
  <v-card>
    <v-card-text class="text-center">
      <v-btn
        color="primary"
        class="my-10"
        :loading="loading"
        :disabled=" buttonIsDisabled"
        @click="downloadCSV"
      >
        Download CSV
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      boardId: '',
      sleeveId: '',
      boardAssingment: {},
    };
  },
  computed: {
    buttonIsDisabled() {
      return (
        this.loading === true
      );
    },
  },
  methods: {
    downloadCSV() {
      const url = `${config.VUE_APP_API_BASE_URL}/download`;
      this.loading = true;
      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          const csv = this.convertJsontoCSV(response.data);
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([csv], { type: 'csv' }));
          a.download = 'orderSystem.csv';
          a.click();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon niet downloaden: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    convertJsontoCSV(downloadResult) {
      const boardAssignmentsInfo = downloadResult.download;
      const keys = [
        'board id',
        'sleeveNr',
        'type sleeve',
        'maat',
        'kleur',
        'sleeve/revision',
        'sleeve/comment',
        'type board',
        'iccid',
        'board/revision',
        'board/comment',
        'board/createdAt',
        'board/pairedAt',
      ];
      const values = Object.values(boardAssignmentsInfo).map(
        (boardAssignmentInfo) => keys
          .map((key) => boardAssignmentInfo[key])
          .join(';'),
      );
      return `${keys.join(';')}\r\n${values.join('\r\n')}`;
    },
  },
};
</script>
