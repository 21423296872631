<template>
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col>
            <v-text-field
              v-model="newSleeve.publicId.value"
              label="PublicId"
              :error-messages="newSleeve.publicId.errors"
              @keyup="validateSleevePublicId"
              @input="newSleeve.publicId.errors = []"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="newSleeve.size.value"
              :items="['S', 'M', 'L', 'XL']"
              label="Maat"
              :error-messages="newSleeve.size.errors"
              @keyup="validateSleeveSize"
              @input="newSleeve.size.errors = []"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="newSleeve.color.value"
              :items="[
                {
                  dutch: 'Zwart',
                  english: 'Black',
                },
                {
                  dutch: 'Navy Blue',
                  english: 'Navy Blue',
                },
                {
                  dutch: 'Soft Grey',
                  english: 'Soft Grey',
                },
              ]"
              item-text="dutch"
              item-value="english"
              label="Kleur"
              :error-messages="newSleeve.color.errors"
              @blur="validateSleeveColor"
              @input="newSleeve.color.errors = []"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="newSleeve.type.value"
              :items="['Shorts', 'Belt']"
              label="Type"
              :error-messages="newSleeve.type.errors"
              @blur="validateSleeveType"
              @input="newSleeve.type.errors = []"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="newSleeve.revision.value"
              label="Revisie"
              :error-messages="newSleeve.revision.errors"
              @keyup="validateSleeveRevision"
              @input="newSleeve.revision.errors = []"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="newSleeve.comment.value"
              label="Opmerkingen"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              :loading="sleeveLoading"
              :disabled="buttonIsDisabled"
              @click="createSleeve"
            >
              Maak aan
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="mt-10">
        <v-col>
          <span>CSV bestand voorbeeld:</span><br>
          <span>
            <i>
              sleeves/publicId;sleeves/size;sleeves/color,
              sleeves/type;sleeves/revision;sleeves/comment
            </i> </span><br>
          <span><i>123456;M,Black;Bottom;1;comment</i></span>
          <v-file-input
            v-model="newSleeve.csv.value"
            :error-messages="newSleeve.csv.errors"
            accept="text/csv"
            show-size
            label="Selecteer een CSV file"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="buttonIsDisabled"
            @click="handleFileUpload"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      sleeveLoading: false,
      newSleeve: {
        publicId: {
          value: '',
          errors: [],
        },
        size: {
          value: '',
          errors: [],
        },
        color: {
          value: '',
          errors: [],
        },
        type: {
          value: '',
          errors: [],
        },
        revision: {
          value: '',
          errors: [],
        },
        comment: {
          value: '',
          errors: [],
        },
        csv: {
          value: null,
          errors: [],
        },
      },
    };
  },
  computed: {
    buttonIsDisabled() {
      return (
        this.loading === true
        || this.sleeveLoading === true
      );
    },
  },
  methods: {
    createSleeve() {
      this.validateForm();

      if (this.newSleeve.publicId.errors.length > 0) return;
      if (this.newSleeve.size.errors.length > 0) return;
      if (this.newSleeve.color.errors.length > 0) return;
      if (this.newSleeve.type.errors.length > 0) return;
      if (this.newSleeve.revision.errors.length > 0) return;

      const url = `${config.VUE_APP_API_BASE_URL}/sleeve`;

      this.sleeveLoading = true;

      const data = {
        publicId: this.newSleeve.publicId.value,
        size: this.newSleeve.size.value,
        color: this.newSleeve.color.value,
        type: this.newSleeve.type.value,
        revision: this.newSleeve.revision.value,
        comment: this.newSleeve.comment.value,
      };

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Sleeve succesvol aangemaakt.',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon de sleeve niet aanmaken: ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.sleeveLoading = false;
        });
    },

    resetFields() {
      this.newSleeve = {
        publicId: {
          value: '',
          errors: [],
        },
        size: {
          value: '',
          errors: [],
        },
        color: {
          value: '',
          errors: [],
        },
        type: {
          value: '',
          errors: [],
        },
        revision: {
          value: '',
          errors: [],
        },
        comment: {
          value: '',
          errors: [],
        },
        csv: {
          value: null,
          errors: [],
        },
      };
    },

    readFile() {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.readAsText(this.newSleeve.csv.value);
        reader.onload = () => {
          resolve(this.convertCSVtoJSON(reader.result));
        };
        reader.onerror = reject;
      });
    },

    convertCSVtoJSON(text) {
      const lines = text.split(/\r?\n/);
      const subHeaderArr = [];
      const type = lines[0].split(';')[0].split('/')[0];
      lines[0].split(';').forEach((subHeader) => {
        subHeaderArr.push(subHeader.split('/')[1]);
      });
      const dataArray = [];
      const linesSliced = lines.slice(1);
      for (let i = 0; i < linesSliced.length; i += 1) {
        const line = linesSliced[i];
        const fields = line.split(';'); // e.g. ['123','30cm']
        if (line.trim().length > 0) {
          dataArray.push(Object.fromEntries(subHeaderArr.map((sh, j) => [sh, fields[j].trim()])));
        }
      }
      const output = {};
      output[type] = dataArray;
      return JSON.stringify(output);
    },

    async handleFileUpload() {
      this.validateCsvUpload();
      const file = await this.readFile();
      const url = `${config.VUE_APP_API_BASE_URL}/sleeves`;
      this.loading = true;
      const data = file;
      axios({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: `${this.newSleeve.csv.value.name} is succesvol verwerkt.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
          this.resetFields();
        })
        .catch((error) => {
          let { message } = error;
          if (error.response) {
            message = error.response.data.errors
              ? `${error.response.data.message}: ${error.response.data.errors}`
              : error.response.data.message;
          } else if (error.request) {
            message = error.request;
          }
          this.$store.commit('app/snackbarText', {
            text: `Kon ${this.newSleeve.csv.value.name} niet verwerken. ${message}.`,
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateForm() {
      this.validateSleevePublicId();
      this.validateSleeveSize();
      this.validateSleeveColor();
      this.validateSleeveType();
      this.validateSleeveRevision();
    },

    validateSleevePublicId() {
      this.newSleeve.publicId.errors = [];
      this.newSleeve.publicId.value = this.newSleeve.publicId.value.trim();
      if (!this.newSleeve.publicId.value) {
        this.newSleeve.publicId.errors.push('PublicId is verplicht.');
      }
      const pattern = /^\d+$/;
      if (
        !pattern.test(this.newSleeve.publicId.value)
        || !(this.newSleeve.publicId.value.length === 6)
      ) {
        this.newSleeve.publicId.errors.push(
          'PublicId moet 6 cijfers lang zijn.',
        );
      }
    },

    validateSleeveSize() {
      this.newSleeve.size.errors = [];
      this.newSleeve.size.value = this.newSleeve.size.value.trim();
      if (!this.newSleeve.size.value) {
        this.newSleeve.size.errors.push('Maat is verplicht.');
      }
    },

    validateSleeveColor() {
      this.newSleeve.color.errors = [];
      this.newSleeve.color.value = this.newSleeve.color.value.trim();
      if (!this.newSleeve.color.value) {
        this.newSleeve.color.errors.push('Kleur is verplicht.');
      }
    },

    validateSleeveType() {
      this.newSleeve.type.errors = [];
      this.newSleeve.type.value = this.newSleeve.type.value.trim();
      if (!this.newSleeve.type.value) {
        this.newSleeve.type.errors.push('Type is verplicht.');
      }
    },

    validateSleeveRevision() {
      this.newSleeve.revision.errors = [];
      this.newSleeve.revision.value = this.newSleeve.revision.value.trim();
      const pattern = /^\+?[1-9]\d*$/;
      if (
        this.newSleeve.revision.value
        && !pattern.test(this.newSleeve.revision.value)
      ) {
        this.newSleeve.revision.errors.push(
          'Revisie moet een positief cijfer zijn.',
        );
      }
    },

    validateCsvUpload() {
      this.newSleeve.csv.errors = [];
      if (!this.newSleeve.csv.value) {
        this.newSleeve.csv.errors.push('CSV file is verplicht.');
      }
    },
  },
};
</script>
